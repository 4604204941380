@import 'style/style.module';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 80px;
  background-color: transparent;
  transition: background-color 0.2s ease;

  @media only screen and (max-width: 767px) {
    height: 60px;
  }

  &.bg {
    background-color: $dark;
  }

  .mainContainer {
    @include mainContainer;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &_toggle {
    display: none;
    @media only screen and (max-width: 767px) {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      position: relative;
      z-index: 101;
      background: $mdark;
      cursor: pointer;
      user-select: none;
      transform: scale(1);
      margin-right: 15px;
      .line {
        flex: 0 0 auto;
        position: relative;
        background: $white;
        width: 16px;
        height: 2px;
        border-radius: 1px;
        margin: 3px 0;
        transform: translate3d(0, 0, 0) rotate(0deg);
        transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: inherit;
        background: $grad;
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      &:hover:before {
        opacity: 1;
      }
    }
  }
  .activeToggle {
    @extend .header_toggle;
    .line {
      &:first-child {
        transform: translate3d(0, 4px, 0) rotate(-135deg);
      }
      &:last-child {
        transform: translate3d(0, -4px, 0) rotate(135deg);
      }
    }
  }
  &_logo {
    flex: 0 0 auto;
    width: 129px;
    height: 25px;
    cursor: pointer;
    background: url(/images/logo.svg) no-repeat 0 50%;
    background-size: contain;
    position: relative;
    z-index: 101;
    @media only screen and (max-width: 767px) {
      width: 155px;
      height: 25px;
    }
  }
  &_left {
    margin-left: 25px;
    display: flex;
    align-items: center;
    width: 100%;
    @media only screen and (max-width: 767px) {
      display: none;
    }
    &_nav {
      display: flex;
      align-items: center;
      &_link {
        color: $grey;
        align-items: center;
        gap: 9px;
        padding: 8px 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.45;
        cursor: pointer;
        display: inline-flex;
        vertical-align: top;
        transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        position: relative;
        &:hover {
          color: $white;
          svg {
            fill: $white;
          }
        }
        svg {
          fill: $grey;
          width: 13px;
          height: 8px;
          transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &:nth-child(4) {
          svg {
            width: 15px;
            height: 14px;
          }
        }
        .list {
          padding: 17px 0;
          display: flex;
          flex-direction: column;
          height: auto;
          margin: 0;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          transition-property: transform, opacity, visibility;
          transition-duration: 0.2s;
          transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          background: $mdark;
          box-shadow: 0 6px 22px rgb(93 98 109 / 10%);
          border-radius: 20px;
          opacity: 0;
          visibility: hidden;
          .listItem {
            padding: 13px 58px 13px 25px;
            white-space: nowrap;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 15px;
            svg {
              transform: rotate(0);
              width: 35px;
              height: 36px;
            }
            .textWrapper {
              .itemTitle {
                font-weight: 600;
                font-size: 14px;
              }
              .itemSubTitle {
                color: $grey;
                font-weight: 500;
                font-size: 12px;
                line-height: 1.4;
                margin: 2px 0 0 0;
              }
            }
            &:after {
              position: absolute;
              right: 23px;
              content: '';
              flex: 0 0 auto;
              background: url(/img/menu/arrow-sub.svg) no-repeat 50% 50%;
              background-size: contain;
              width: 8px;
              height: 12px;
              margin-left: 10px;
              transition-property: transform, opacity;
              transition-duration: 0.2s;
              transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              transform: translate3d(-10px, 0, 0);
              opacity: 0;
            }
          }
          .listItemActive {
            opacity: 1;
          }
          .listItemNotActive {
            opacity: 0.5;
          }
        }
      }
      .active {
        @extend .header_left_nav_link;
        color: $white;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          //background: $grad;
          margin: 10px 0;
          mix-blend-mode: darken;
        }
      }
      .openActive {
        @extend .header_left_nav_link;
        position: relative;
        svg {
          transform: rotate(180deg);
        }
        &:nth-child(4) {
          svg {
            transform: rotate(0);
          }
        }
        .list {
          transform: translate3d(-50%, 7px, 0);
          opacity: 1;
          visibility: visible;
          transition-property: transform, opacity, visibility;
          transition-duration: 0.2s;
          transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          .listItem:hover {
            &:after {
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }
          }
        }
      }
    }
    &_lang {
      position: relative;
      margin-left: auto;

      &_toggle {
        cursor: pointer;
        text-align: center;
        color: $white;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        background: $mdark;
        border-radius: 10px;
        position: relative;
        &:active {
          transform: scale(0.95);
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: inherit;
          background: $grad;
          opacity: 0;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          z-index: 4;
        }

        &:hover:after {
          opacity: 1;
          background: $grad;
        }
        span {
          position: relative;
          z-index: 5;
        }
      }
      &_toggleOpen {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: inherit;
          background: $grad;
          opacity: 1;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          z-index: 4;
        }
      }

      &_sub {
        flex-direction: column;
        align-items: stretch;
        gap: 5px;
        position: absolute;
        top: 100%;
        right: 0;
        padding: 10px;
        background: $mdark;
        box-shadow: 0 6px 22px rgb(93 98 109 / 10%);
        border-radius: 14px;
        margin: 10px 0 0 0;
        min-width: 150px;
        transition-property: transform, opacity, visibility;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: translateY(0px);
        opacity: 0;
        visibility: hidden;
        .toggleItem {
          display: block;
          font-size: 14px;
          border-radius: 10px;
          padding: 10px;
          flex: 0 0 auto;
          font-weight: 600;
          line-height: 1.45;
          cursor: pointer;
          a {
            text-decoration: none;
          }
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
        }
        .toggleItemActive {
          @extend .toggleItem;
          background-color: rgba(255, 255, 255, 0.1);
          color: $white;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
      &_subOpen {
        transform: translateY(7px);
        transition-duration: 0.2s;
        opacity: 1;
        visibility: visible;
      }
    }

    &_login {
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        display: block;
        content: '';
        width: 1.5px;
        height: 15px;
        margin: 12px 20px;
        border-radius: 2px;
        background-color: #484554;
      }

      &_toggle {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        color: $white;
        height: 40px;
        padding: 0 15px;
        background: $mdark;
        border: none;
        border-radius: 10px;
        line-height: 40px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
      }

      &_chevron {
        fill: $white;

        .active & {
          transform: rotate(180deg);
        }
      }

      &_dropdown {
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        padding: 15px 0;
        border-radius: 20px;
        background-color: $mdark;
        white-space: nowrap;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-7px);
        transition: all 0.2s ease;

        &.active {
          transform: translateY(0);
          opacity: 1;
          pointer-events: auto;
        }

        &:has(.header_left_login_item:hover) {
          .title,
          .subtitle {
            opacity: 0.5;
          }
        }
      }

      &_item {
        display: flex;
        gap: 15px;
        padding: 7.5px 25px;
        cursor: pointer;
        transition: opacity 0.3s ease;

        & > *:first-child {
          flex: 0 0 35px;
        }

        .title {
          font-size: 14px;
          font-weight: 600;
          color: $white;
        }

        .subtitle {
          font-size: 12px;
          font-weight: 500;
        }

        &:hover {
          .title,
          .subtitle {
            opacity: 1 !important;
          }
        }
      }
    }
  }
  .leftMobile {
    display: none;
    height: 100%;

    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      position: fixed;
      height: 100%;
      background: black;
      top: 0;
      left: 0;
      padding: 85px 20px 35px 20px;
      margin: 0;
      .top {
        display: flex;
        flex-direction: column;
        .nav {
          color: $grey;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .link {
            padding: 10px 0;
            font-weight: bold;
            font-size: 24px;
            line-height: 1.3;
            &:last-child {
              padding-bottom: 0;
            }
          }
          .openActive {
            color: $white;
            .list {
              display: flex;
              flex-direction: column;
              margin-top: 15px;
              padding: 10px 30px 10px;
              border-left: 1px solid $borders;
              transition: 0.4s linear;
              .listLink {
                text-decoration: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 145%;
                margin-bottom: 25px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        .lang {
          margin-top: 30px;
          .sub {
            font-weight: 600;
            font-size: 18px;
            line-height: 145%;
            color: $grey;
            height: 100%;
            opacity: 1;
            visibility: visible;
            .toggleItem:not(:last-child) {
              padding-right: 30px;
            }
            .toggleItemActive {
              color: $white;
            }
          }
        }
      }

      .follow {
        justify-items: flex-end;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $grey;
        text-transform: uppercase;
        .social {
          margin-top: 10px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
          .icon {
            flex: 0 0 auto;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $mdark;
            width: 50px;
            height: 50px;
            border-radius: 14px;
            transform: scale(1);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            color: $white;
            text-decoration: none;
            z-index: 2;
            &:active {
              transform: scale(0.95);
            }
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              border-radius: inherit;
              background: $grad;
              opacity: 0;
              transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
            }
            svg {
              position: relative;
              z-index: 5;
            }
            &:hover:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
