@import 'style/style.module';
.main {
  background-size: 1440px auto;
  flex: 0 0 auto;
  .landingFooter {
    padding-top: 150px;
    padding-bottom: 35px;
    @media only screen and (max-width: 1023px) {
      padding-top: 100px;
      padding-bottom: 40px;
    }
  }
  .mainContainer {
    @include mainContainer;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 767px) {
      max-width: 390px;
      align-items: flex-start;
    }

    .logo {
      flex: 0 0 auto;
      width: 161px;
      height: 30px;
      background: url(/images/logo.svg) no-repeat 0 50%;
      background-size: contain;
      position: relative;
      z-index: 101;
      margin-bottom: 60px;
      @media only screen and (max-width: 1023px) {
        margin-bottom: 40px;
      }
    }
    .footMenu {
      max-width: 1060px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 40px 20px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $grey;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 1 auto;
        order: 1;
        @media only screen and (max-width: 767px) {
          order: 2;
        }
        a {
          margin-top: 15px;
          gap: 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.45;
          text-transform: none;
          letter-spacing: normal;
          color: $white;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-decoration: none;
          &:first-child {
            margin-top: 30px;
            @media only screen and (max-width: 1023px) {
              margin-top: 20px;
            }
          }
          &:hover {
            display: inline-block;
            vertical-align: top;
            background: linear-gradient(90deg, #e571ff 0%, #ff8937 97.22%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }
        }
      }
      .follow {
        order: 2;
        flex: 1 1 auto;
        @media only screen and (max-width: 767px) {
          order: 1;
        }
        .social {
          margin-top: 30px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
          @media only screen and (max-width: 1023px) {
            margin-top: 20px;
          }
          .icon {
            flex: 0 0 auto;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $mdark;
            width: 50px;
            height: 50px;
            border-radius: 14px;
            transform: scale(1);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            color: $white;
            text-decoration: none;
            z-index: 2;

            &.twitter svg {
              scale: 2; // todo refactor icons
            }

            &:active {
              transform: scale(0.95);
            }
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              border-radius: inherit;
              background: $grad;
              opacity: 0;
              transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
            }
            svg {
              position: relative;
              z-index: 5;
            }
            &:hover:before {
              opacity: 1;
            }
          }
        }
      }
    }
    .footText {
      margin-top: 85px;
      padding-top: 35px;
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: flex-start;
      gap: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      line-height: 1.4;
      color: $grey;
      justify-content: space-between;
      @media only screen and (max-width: 1023px) {
        margin-top: 60px;
        padding-top: 30px;
        gap: 0;
      }
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
      .prod {
        @media only screen and (max-width: 767px) {
          font-weight: bold;
          font-size: 16px;
          color: $white;
          margin: 0 0 10px 0;
        }
      }
      .rights {
        text-align: center;
        @media only screen and (max-width: 767px) {
          font-weight: 500;
          font-size: 10px;
          line-height: 1.4;
          text-align: left;
          max-width: 200px;
        }
      }
      .links {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        @media only screen and (max-width: 767px) {
          align-items: flex-start;
          font-weight: 600;
          font-size: 12px;
          opacity: 0.9;
          margin-top: 30px;
        }
        a {
          text-decoration: none;
        }
      }
    }
  }
}
